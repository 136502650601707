import React from "react";
import Index from "./components/pages/Index";

function App() {
  return (
    <div>
      <Index></Index>
    </div>
  );
}

export default App;
